import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import gcc_logo from '../images/gcc-logo.png';

import img_1 from '../images/IMG_2093.jpg'; 
import img_2 from '../images/IMG_2096.jpg'; 
import img_3 from '../images/IMG_2104.jpg'; 
import img_4 from '../images/IMG_2105.jpg'; 
import img_5 from '../images/IMG_2107.jpg'; 
import img_6 from '../images/IMG_2109.jpg'; 

import img_concept_1 from '../images/concept/balls.png';
import img_concept_2 from '../images/concept/Barna.png';
import img_concept_3 from '../images/concept/Buddha-cropped.jpg';
import img_concept_4 from '../images/concept/holo-2.png';
import img_concept_5 from '../images/concept/holo.png';
import img_concept_6 from '../images/concept/hologram.2.jpg';
import img_concept_8 from '../images/concept/IMG_0757.png';
import img_concept_9 from '../images/concept/IMG_0758.png';


import img_dev_2 from '../images/dev-images/IMG_2429.jpg';
import img_dev_3 from '../images/dev-images/IMG_2432.png';
import img_dev_4 from '../images/dev-images/IMG_2433.png';
import img_dev_5 from '../images/dev-images/IMG_2434.png';
import img_dev_6 from '../images/dev-images/IMG_2435.png';




const Home = () => {
    
    let siteImages = [ img_1, img_2, img_3, img_4, img_5, img_6 ];
    
    let carouselItems = siteImages.map((item, idx) => {
        return (
            <Carousel.Item key={'img-' + idx}>
                <img
                    className="d-block w-100"
                    src={item}
                    alt="Inside Barna Woods"
                />                           
            </Carousel.Item>
        )
    });

    let conceptImages = [ img_concept_1, img_concept_2, img_concept_3, img_concept_4, img_concept_5, img_concept_6, img_concept_8, img_concept_9 ];

    let carouselConceptItems = conceptImages.map((item, idx) => {
        return (
            <Carousel.Item key={'concept-img-' + idx}>
                <img
                    className="d-block w-100"
                    src={item}
                    alt="Concept Images Woods"
                />                           
            </Carousel.Item>
        )
    });


    let devImages = [ img_dev_2, img_dev_3, img_dev_4, img_dev_5, img_dev_6 ];

    let carouselDevItems = devImages.map((item, idx) => {
        return (
            <Carousel.Item key={'dev-img-' + idx}>
                <img
                    className="d-block w-100"
                    src={item}
                    alt="Development Images"
                />                           
            </Carousel.Item>
        )
    });

    return (
        <div>
            <div className="row">
                <div className="col" id="home-intro">
                    <h1>Cosáin Virtual</h1>
                    <p className="tagline">
                        Exploring digital art in the physical world
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-10 offset-md-1 pt-4 pb-4">
                    <div style={{padding:'65.81% 0 0 0', position:'relative'}}>
                        
                        <iframe src="https://player.vimeo.com/video/583594189?title=0&byline=0&portrait=0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen frameBorder="0" style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}></iframe>
                    </div>
                   
                    <p className="mb-5 pb-5 text-center">
                        <strong>Cosáin Virtual</strong> – A mental health visual arts project which uses location-based augmented reality technologies, supported by the Galway County Council.
                    </p>
                </div>
            </div>
            <div className="row" id="about">
                <div className="col-12 col-md-6 offset-md-3 pt-4 pb-4">
                    <h2>Overview</h2>
                    <p>
                        <strong>Cosáin Virtual</strong> is a visual art project based on positive mental health for the residents surrounding Barna Woods in County Galway. The project aims to design and create location-based augmented reality artworks at Barna Woods, County Galway. The AR experience connects the audience with nature, positive mental health and technology.
                    </p>

                    <p>The project is a collaboration between <a href="http://ranasamir.com">Samir Mahmood</a> and <a href="http://www.davidkelly.ie">David Kelly</a>, and is currently in its research and development phase. Work on this project was generously supported by the <a href="http://www.galway.ie/en/services/arts/">Galway County Council Arts Office</a> in 2021.</p>
                </div>
            </div>
            
            <div id="background" className="row">
                <div className="col-12 col-md-6 offset-md-3 pt-4 pb-4">
                    <h2>Project Context</h2>
                    <p>
                       Viewing and engaging with the visual arts offer several benefits. These include decreasing negative emotions and increasing positive ones, reducing levels of depression, stress, fatigue and anxiety. In addition, it increases self-respect, self-worth and self-esteem; encourage and stimulate re-engagement with the wider, everyday social world.
                    </p>
                    <p>
                        AR is an immersive technology where 3D content is overlaid onto the user’s real-world environment, seen through a headset or a mobile device, such as a phone. In the last decade the applied approach for the use of augmented reality (AR) in health psychology and visual arts has grown significantly. These technologies have been used to address phobias, stress-related disorders, depression, eating disorders, and chronic pain.
                    </p>
                    <p>
                        In an AR experience  a user can feel “as if they are” in a reality that does not exist in external world  and  experience  their body inside the virtual environment. Combining this technology with the visual arts can help improve people’s quality of life, mental health and psychological well-being promotion.

                    </p>

                    <h3 className="mt-5">Project Objectives</h3>

                    <ul>
                        <li>Research and test an AR-based visual arts intervention based on positive mental health and well-being in Barna Woods, Galway.</li>
                        <li>Evaluate and recommend strategy to upscale the project by future collaboration between local artists and technologists</li>
                    </ul>
                </div>
            </div>
            
            <div className="row">
                <div className="col-12 col-md-6 offset-md-3 pt-4">                    
                    <h2>Exploring the Site</h2>
                    <p>
                        <a href="http://www.galwaybarnawoods.com/">Barna Woods</a> is an area of woodland located approximately 3 miles from Galway City centre. The woods are owned by Galway City Council and are open to the public. They are managed as part of a large Special Area of Conservation, the Galway Bay Complex, which protects a diverse range of marine, coastal and terrestrial habitats. Barna Woods have been claimed to have the last natural growing oaks in the west of Ireland.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-4">                  
                    <MapContainer center={[53.25976592412507, -9.134031358290542]} zoom={15} scrollWheelZoom={false} style={{ height: '700px'}}>
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        />
                        <Marker position={[53.25976592412507, -9.134031358290542]}>
                            <Popup>
                                Barna Woods
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 offset-md-3 pt-4 pb-4">             
                    <p>
                        Early in the project we looked at various sites within Barna Woods. When considering possible sites for inclusion, several interesting issues arose during our discussions:
                    </p>
                    <ul className="mt-4">
                        <li><strong>Accessibility</strong>: how would a decision to locate artworks away from the woods' main pedestrian path affect who can experience the artworks? For example, could / would someone with a pushchair or wheelchair navigate the often rough terrain? </li>
                        <li><strong>Viewing experience</strong>: do we want seated areas for people to sit and view the works? While we had no intention of disturbing the site, there are several naturally occurring seating areas, such as walls, rocks, etc</li>
                        <li><strong>Displaying the Artworks</strong>: Augmented Reality offers different options for triggering the display of 3D objects. The most commonly used approach involves the use of printed marker images, which, when viewed with an app, display the appropriate 3D object. Given concerns about disturbing the site, a decision was made to use geographic coordinates to display the objects; these coordinates are recognised by the app on the user's mobile device, which allows the display of the artworks. </li>
                    </ul>
                    <Carousel fade className="mt-5">
                        {carouselItems}
                    </Carousel>
                </div>
            </div>

            <section>
                <header className="row">
                    <div className="col-12 col-md-6 offset-md-3 pt-4 pb-4 text-center">
                        <h2>Creation of 3D Visual Artworks</h2>
                    </div>
                </header>
                
                <div className="row">
                    <div className="col-12 col-md-4 offset-md-1 pt-4 pb-4">
                        <p className="mt-5 pt-5">
                            The 3D animations and sketches were created by the artist Samir Mahmood in Blender 2.9. The two animations, a portal and a light ball, were based on the mental health promotion themes of positive emotions, resilience, relationships and health-promoting public spaces.                         
                        </p>
                        <p className="mt-5 ">
                            On the right are concept images produced during the exploratory phase of the project. 
                        </p>

                    </div>
                    <div className="col-12 col-md-6 offset-md-1 pt-4 pb-4">
                        <Carousel fade className="mt-5">
                            { carouselConceptItems }
                        </Carousel>
                        
                    </div>
                </div>
            </section>

            
            <section>
                <header className="row">
                    <div className="col-12 pt-4 pb-4 text-center">
                        <h2>Creating the Augmented Reality Experience</h2>
                    </div>
                </header>
                
                <div className="row">
                    
                    <div className="col-12 col-md-6 pt-4 pb-4">
                        <Carousel fade className="mt-5">
                            { carouselDevItems }
                        </Carousel>
                    </div>
                    <div className="col-12 col-md-4 offset-md-1 pt-4 pb-4">
                         <p className="mt-5 pt-5">
                            The Augmented Reality application was created by <a href="http://www.davidkelly.ie">David Kelly</a> using the <a href="https://unity.com/">Unity Development Platform</a>. For testing purposes, development targeted Apple's iOS mobile devices, such as iPhones and iPads. Unity allows the app to be distributed on Android devices (as well as many other platforms, should it be required).
                        </p>
                        <h3 className="mt-5">Development process</h3>
                        <p>
                            On the left are a sample of screenshots taken while developing the AR experience. Given the impracticality of frequently accessing Barna Woods to test the AR app, much of the initial testing was done locally. Testing included experimenting with both technical issues, such as the management of the location-based portion of the application, for example, <em>how does the application monitor and respond to a user's location?</em> As well as experience-oriented issues, for example, <em>how does the scale of the digital objects affect the user experience of the app?</em> 
                        </p>
                        <h3 className="mt-5">Content selection</h3>
                        <p>
                            A long term vision for the application is to include the work of other artists within the Barna Woods site. To test the viability of doing this, in addition to the digital objects created by Samir, additional content was included from external sources, such as the rotating tree shown in the video below. 
                        </p>                     
                       
                    </div>
                </div>

                <div className="row">
                    
                    <figure className="col-12 col-md-8 offset-md-2 text-center mt-5 mb-5 pb-5">
                        <figcaption className="pb-2">
                            Test of using Unity to place an animated 3D object at a specific latitude & longitude within an Augmented Reality application. 
                        </figcaption>
                        <iframe src="https://player.vimeo.com/video/583123637?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{width:'100%',height:'100%', minHeight: '1000px'}} title="AR Testing - Floating &amp;amp; Rotating Tree"></iframe>
                        
                    </figure>                    
                </div>
            </section>




            <div className="row">
                <div className="col-12 col-md-6 offset-md-3 pt-4 pb-4">
                    <h2>Evaluation</h2>
                     <p>
                        This project was very much a learning process for both collaborators, including managing the collaboration and merging technology with visual art for a public space. On the technical side, exploring issues of site selection, the ability to test a location-based experience, and incorporating a geo-spatial dimension into an AR app proved challenging, but extremely interesting. 
                    </p>
                    <p>
                        The creation of suitable visual content, and translating it into an AR experience, involved bringing in new components into one’s art practice. Learning how to use the software to animate artworks was a fascinating process as it unearthed an inherent potential in 2D artworks to become 3D objects.   
                    </p>
                    <p>
                        The research and experience gained from this new approach will help create an innovative site-specific exhibition in the future. It will serve as an alternative example of how artists in Galway county are able to engage communities when galleries and museums are finding difficulty in holding public events. The use of technology to exhibit artworks enable artists to curate their own gallery experience, showcase their work and interact with the audience.
                    </p>
                    <p> 
                        It opens up the potential of the public realm for artists in a non-destructive way – it allows the city, and the county, to become the gallery.
                    </p>

                    <h3 className="mt-4">Learnings</h3>
                    <ul>
                        <li>
                            Testing site-specific, location-based AR projects is a challenge. In non-location-based development, the project can be built and tested wherever development is taking place. With software tied to a specific site, making adjustments and experimenting with different options becomes much more challenging. Frequent trips to the site are required, before returning to change and rebuild the software.
                        </li>
                        <li>
                            In this project, 3D objects were created using Blender, before being imported into Unity. This workflow caused some problems, where a lack of knowledge of each of the collaborators of the other's software platform led to unforeseen delays in the development process when migrating content from one to the other. 
                        </li>
                        <li>
                            Further testing is required to assess the impact of the trees in the forest on the phone's GPS signal, and its ability to accurately read the user's location in relation to the 3D objects placed in the app. 
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 offset-md-3 pt-4 pb-4">
                    <h2>Next Steps</h2>
                    
                    <p>
                        Upon successful testing of the application further funding from the Irish Arts Council will be sought to develop an exhibition on a larger scale, which will cover a wider area of the woods. An open call will be announced for suitable local artists to contribute to this project. A possible collaboration with the Departments of Public Health and Health Promotion is also being considered.
                    </p>
                </div>
            </div>
            
            <hr/>
            <div className="row">
                <div className="col-12 col-md-6 offset-md-3 pb-4 mb-5 text-center">
                    
                    <h3>Supported by</h3>
                    <ul className="list-inline mb-4">
                        <li className="list-item">
                            <img src={gcc_logo} alt="Galway County Council" style={{padding: '20px', background: '#fff'}} />
                        </li>
                    </ul>
                    <hr/>
                    <p>
                        For further information, please contact <a href="https://ranasamir.com">Samir Mahmood</a> (ranasamirart@gmail.com) or <a href="http://www.davidkelly.ie">David Kelly</a> (hi@davidkelly.ie)
                    </p>
                    <p>
                        Created in Galway, 2021.
                    </p>
                </div>
            </div>

        </div>
    );
}
// 53.263078856564846, -9.133751899747372
export default Home;