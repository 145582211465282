import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from "./components/Home";
import About from "./components/About";


function App() {
  return (

    <Router>
      <main className="container-fluid">
       
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>          
        </Switch>
      </main>
    </Router>
);

}

export default App;
